
.mat-cell,
.mat-footer-cell {
    font-size: 14px;
}

.mat-grid-tile-footer,
.mat-grid-tile-header {
    font-size: 14px;
}
.mat-grid-tile-footer .mat-line,
.mat-grid-tile-header .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box;
}
.mat-grid-tile-footer .mat-line:nth-child(n + 2),
.mat-grid-tile-header .mat-line:nth-child(n + 2) {
    font-size: 12px;
}

.mat-table-sticky,
.mat-table tbody,
.mat-table tfoot,
.mat-table thead,
[mat-footer-row],
[mat-header-row],
[mat-row],
mat-footer-row,
mat-header-row,
mat-row {
    background: inherit;
}
mat-footer-row,
mat-header-row,
mat-row,
td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
    border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-cell,
.mat-footer-cell {
    color: rgba(0, 0, 0, 0.87);
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
legend,
main,
nav,
section {
    display: block;
}

.ant-picker-panel .ant-picker-footer {
    border-top: 1px solid #f0f0f0;
}
.ant-picker-footer-extra:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
}
.ant-layout-footer {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    background: #f0f2f5;
}

nz-content,
nz-footer,
nz-header {
    display: block;
}
.ant-list-footer,
.ant-list-header {
    background: 0 0;
}

.ant-list-bordered .ant-list-footer,
.ant-list-bordered .ant-list-header,
.ant-list-bordered .ant-list-item {
    padding-right: 24px;
    padding-left: 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-footer,
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-item {
    padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-footer,
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-item {
    padding: 16px 24px;
}

.ant-modal-footer {
    background: 0 0;
    border-radius: 0 0 2px 2px;
}
.ant-table-footer,
.ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.85);
    background: #fafafa;
}

.ant-table-footer,
.ant-table-title {
    padding: 16px;
}

.ant-table-footer {
    border-radius: 0 0 2px 2px;
}

.ant-transfer-list-footer {
    border-radius: 0 0 2px 2px;
}
.ant-transfer-rtl .ant-transfer-list-body-search-wrapper,
.ant-transfer-rtl .ant-transfer-list-footer {
    right: 0;
    left: auto;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

.ant-picker-footer {
    line-height: 38px;
    text-align: center;
    border-bottom: 1px solid transparent;
}
.ant-picker-panel .ant-picker-footer {
    border-top: 1px solid #f6f6f6;
}
.ant-picker-footer-extra {
    padding: 0 12px;
    line-height: 38px;
    text-align: left;
}
.ant-picker-footer-extra:not(:last-child) {
    border-bottom: 1px solid #f6f6f6;
}

.ant-drawer-footer {
    flex-shrink: 0;
    padding: 10px;
    border-top: 1px solid #f0f0f0;
}

.ant-empty-footer {
    margin-top: 16px;
}

.ant-layout-footer,
.ant-layout-header {
    flex: 0 0 auto;
}
.ant-layout-header {
    height: 64px;
    padding: 0 24px;
    color: #594c53;
    line-height: 64px;
    background: #fff;
}
.ant-layout-footer {
    padding: 24px 50px;
    color: #594c53;
    font-size: 16px;
    background: #f6f6f6;
}
nz-content,
nz-footer,
nz-header {
    display: block;
}

.ant-list-footer,
.ant-list-header {
    background: transparent;
}
.ant-list-footer,
.ant-list-header {
    padding-top: 12px;
    padding-bottom: 12px;
}

.ant-list-split.ant-list-empty .ant-list-footer {
    border-top: 1px solid #f0f0f0;
}

.ant-list-bordered .ant-list-footer,
.ant-list-bordered .ant-list-header,
.ant-list-bordered .ant-list-item {
    padding-right: 24px;
    padding-left: 24px;
}

.ant-list-bordered.ant-list-sm .ant-list-footer,
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-item {
    padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-footer,
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-item {
    padding: 16px 0;
}

nz-list,
nz-list-empty,
nz-list-footer,
nz-list-header,
nz-list-item-extra,
nz-list-pagination,
nz-list nz-spin {
    display: block;
}


.ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 10px 10px;
}
.ant-modal-footer button + button {
    margin-bottom: 0;
    margin-left: 8px;
}
.ant-modal-wrap-rtl .ant-modal-footer {
    text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-footer button + button {
    margin-right: 8px;
    margin-left: 0;
}
.ant-page-header.has-footer {
    padding-bottom: 0;
}
.ant-page-header-footer {
    margin-top: 16px;
}
.ant-page-header-footer .ant-tabs-bar {
    margin-bottom: 1px;
    border-bottom: 0;
}
.ant-page-header-footer .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 0;
    font-size: 16px;
}
.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
    float: right;
}
nz-page-header,
nz-page-header-content,
nz-page-header-footer {
    display: block;
}
.ant-table-middle .ant-table-footer,
.ant-table-middle .ant-table-tbody > tr > td,
.ant-table-middle .ant-table-thead > tr > th,
.ant-table-middle .ant-table-title {
    padding: 12px 8px;
}
.ant-table-small .ant-table-footer,
.ant-table-small .ant-table-tbody > tr > td,
.ant-table-small .ant-table-thead > tr > th,
.ant-table-small .ant-table-title {
    padding: 8px;
}

.ant-table.ant-table-bordered .ant-table-footer {
    border: 1px solid #f0f0f0;
    border-top: 0;
}

.ant-table-footer {
    padding: 16px;
    color: #594c53;
    background: #fff;
}
.ant-table-footer {
    border-radius: 0 0 10px 10px;
}

nz-table,
nz-table-inner-default,
nz-table-inner-scroll,
nz-table-selection,
nz-table-title-footer {
    display: block;
}

.ant-transfer-list-with-footer {
    padding-bottom: 34px;
}

.ant-transfer-list-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 10px 10px;
}
.ant-transfer-rtl .ant-transfer-list-footer {
    right: 0;
    left: auto;
}